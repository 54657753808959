<template>
    <div class="container">
        <div class="header">
            <div class="header-row">
                <strong>Tên Máy</strong>
            </div>
            <div class="header-row product-name">
                <span>{{ productName }}</span>
            </div>
            <div class="header-row imei">
                <strong>IMEI</strong>
                <span>{{ imeiCode }}</span>
            </div>
        </div>
        <div class="title"></div>
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th>Nguyên Gốc</th>
                    <th>Đã Sửa Chữa</th>
                </tr>
            </thead>
            <tr>
                <td>Mainboard</td>
                <td><input type="checkbox" style="display: block; margin: 0 auto; width: 15px; height: 15px;" /></td>
                <td><input type="checkbox" style="display: block; margin: 0 auto; width: 15px; height: 15px;" /></td>
            </tr>
            <tr>
                <td>Camera Trước</td>
                <td><input type="checkbox" style="display: block; margin: 0 auto; width: 15px; height: 15px;" /></td>
                <td><input type="checkbox" style="display: block; margin: 0 auto; width: 15px; height: 15px;" /></td>
            </tr>
            <tr>
                <td>Camera Sau</td>
                <td><input type="checkbox" style="display: block; margin: 0 auto; width: 15px; height: 15px;" /></td>
                <td><input type="checkbox" style="display: block; margin: 0 auto; width: 15px; height: 15px;" /></td>
            </tr>
            <tr>
                <td>Khung viền</td>
                <td><input type="checkbox" style="display: block; margin: 0 auto; width: 15px; height: 15px;" /></td>
                <td><input type="checkbox" style="display: block; margin: 0 auto; width: 15px; height: 15px;" /></td>
            </tr>
            <tr>
                <td>Loa Trong</td>
                <td><input type="checkbox" style="display: block; margin: 0 auto; width: 15px; height: 15px;" /></td>
                <td><input type="checkbox" style="display: block; margin: 0 auto; width: 15px; height: 15px;" /></td>
            </tr>
            <tr>
                <td>Loa Ngoài</td>
                <td><input type="checkbox" style="display: block; margin: 0 auto; width: 15px; height: 15px;" /></td>
                <td><input type="checkbox" style="display: block; margin: 0 auto; width: 15px; height: 15px;" /></td>
            </tr>
            <tr>
                <td>Pin</td>
                <td><input type="checkbox" style="display: block; margin: 0 auto; width: 15px; height: 15px;" /></td>
                <td><input type="checkbox" style="display: block; margin: 0 auto; width: 15px; height: 15px;" /></td>
            </tr>
            <tr>
                <td>Dung lượng pin còn lại</td>
                <td></td>
                <td></td>
            </tr>
        </table>
        <div class="footer">
            <ul>
                <li>Bảo hành 1 Đổi 1 trong vòng 6 tháng</li>
                <li>Dùng thử miễn phí trong 7 Ngày</li>
                <li>Bảo hành Pin 10 năm</li>
            </ul>
        </div>
        <div>
            <img class="logo" src="/media/logos/logo-gdn-03.png" alt="Logo">
        </div>
    </div>
</template>

<script>
export default {
    name: "PrintImeiProductV2",
    props: [
        'productName',
        'imeiCode',
    ],
    methods: {},
};
</script>

<style scoped>
.container {
    font-family: Arial, sans-serif;
    width: 66mm;
    height: 136mm;
    border: 1px solid black;
    padding: 0;
    margin: 0;
    border-radius: 2mm;
    overflow: hidden;
    position: relative; /* Để có thể dùng absolute */
}

.header {
    font-family: Arial, sans-serif;
    background-color: transparent;
    font-size: 9pt;
    margin: 1mm 2mm;
    display: flex;
    flex-direction: column;
    gap: 1mm;
    /* Khoảng cách giữa các dòng */
    height: 22mm;
}

.header-row {
    display: flex;
    align-items: center;
    gap: 3mm;
    /* Tạo khoảng cách giữa tiêu đề và nội dung */
}

.product-name {
    height: 10mm; /* Chiều cao cố định */
    min-height: 10mm; /* Đảm bảo không bị thu nhỏ */
    max-height: 10mm; /* Tránh bị phóng to */
    font-weight: normal;
    font-size: 9pt;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Giới hạn 2 dòng */
    line-clamp: 2; /* Giới hạn 2 dòng */
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.imei {
    display: flex;
    height: 5mm; /* Chiều cao cố định */
    min-height: 5mm; /* Đảm bảo không bị thu nhỏ */
    max-height: 5mm; /* Tránh bị phóng to */
    overflow: hidden; /* Ẩn nội dung bị tràn */
    box-sizing: border-box; /* Đảm bảo padding không làm thay đổi kích thước */
}


.title {
    display: flex; /* Kích hoạt flexbox */
    justify-content: center; /* Căn giữa theo chiều ngang */
    align-items: center;
    background-color: transparent;
    color: white;
    font-size: 12pt;
    padding: 2mm;
    font-weight: bold;
    width: 100%;
    height: 7.57mm;
}

table {
    width: 100%;
    font-size: 8pt;
    border-collapse: collapse;
    table-layout: fixed;
    /* Chia đều cột */
}

table thead th {
    font-weight: normal;
    padding: 1mm;
    text-align: center;
}

table th,
table td {
    width: 33.33%;
}

td {
    padding: 1.5mm;
    border-bottom: 1px dotted black;
}

.footer {
    font-size: 9pt;
    padding-top: 5mm;
}
.footer ul {
    padding: 0;
    margin: 0;
    list-style-type: none; /* Loại bỏ bullet mặc định */
}

.footer li {
    position: relative;
    padding-left: 35px;
}

.footer li::before {
    content: "•"; /* Thêm dấu bullet */
    position: absolute;
    left: 0;
    color: black;
    font-weight: bold;
    margin-left: 5px;
}


.logo {
    position: absolute;
    bottom: 2mm; /* Cách mép dưới một chút */
    left: 50%;
    transform: translateX(-50%);
    width: 40mm;
}
</style>